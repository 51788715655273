import { useCallback } from 'react'
import guestInfoState from '../../../redux/dashboardInputs/actions/guestInfoState'
import type { GuestInfoState } from '../../../redux/dashboardInputs/interfaces/GuestInfoState'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'
import { DEPRECATED_MainMenuContent } from '../../../redux/studioCommand/interfaces/UpdateMainMenuContent'

export type UseGuestInfoUpdate = [GuestInfoState, (props: DEPRECATED_MainMenuContent) => void]

export const useGuestInfoUpdate = (): UseGuestInfoUpdate => {
	const { guestInfo } = useAppSelector(({ dashboardInputs: { guestInfo } }: StateTree) => ({
		guestInfo,
	}))
	const dispatch = useAppDispatch()
	const sendUpdateGuestInfoState = useCallback((props: DEPRECATED_MainMenuContent) => guestInfoState(props)(dispatch), [dispatch])

	return [guestInfo, sendUpdateGuestInfoState]
}
