import { useCallback } from 'react'
import type { StateTree } from '../../redux/interfaces'
import { overwriteDefaultValuesFailure, overwriteDefaultValuesStart } from '../../redux/studioCommand/actions/overwriteDefaultValues'
import {
	saveScheduledShowInformationFailure,
	saveScheduledShowInformationStart,
} from '../../redux/studioCommand/actions/saveScheduledShowInformation'
import { saveShowInformationFailure, saveShowInformationStart } from '../../redux/studioCommand/actions/saveShowInformation'
import { warmUp } from '../../redux/studioCommand/actions/warmUp'
import { onWSResetStateAction } from '../../redux/webSocket/actions/reset'
import { useAppDispatch, useAppSelector } from '.'
import useCountdownShow from './commands/useCountdownShow'
import { useDefaultValues } from './commands/useDefaultValues'
import useDisplayBeatGuest from './commands/useDisplayBeatGuest'
import useDisplayWinners from './commands/useDisplayWinners'
import { useGetScheduledShow } from './commands/useGetScheduledShow'
import usePickSeasonWinner from './commands/usePickSeasonWinner'
import usePickWinners from './commands/usePickWinners'
import usePrepareCelebration from './commands/usePrepareCelebration'
import useResetChallenges from './commands/useResetChallenges'
import { useSaveShow } from './commands/useSaveShow'
import useStartGame from './commands/useStartGame'
import useStopShow from './commands/useStopShow'
import { useAllFieldsValidation } from './validations/useAllFieldsValidation'
import useShowLuckyWinners from './commands/useShowLuckyWinners'
import useStartPartBGame from './commands/useStartPartBGame'

export type PromiseVoid = () => Promise<void>
export type ToggleCommand = (isAvailable: boolean) => void

interface UseCommands {
	commands: StateTree['commands']
	sendWarmUp: PromiseVoid
	sendStartCountdownAndShow: PromiseVoid
	sendStartGame: PromiseVoid
	sendPrepareCelebration: PromiseVoid
	sendDisplayBeatGuest: PromiseVoid
	sendPickWinners: PromiseVoid
	sendDisplayWinners: PromiseVoid
	sendResetChallenges: PromiseVoid
	sendShowLuckyWinners: PromiseVoid
	sendStartPartBGame: PromiseVoid
	sendStopShow: PromiseVoid
	toggleCommands: ToggleCommand
	saveShowWithValidation: (isNextShow: boolean) => Promise<void>
	getScheduledShow: PromiseVoid
	overwriteDefaultValuesWithValidation: PromiseVoid
	sendPickSeasonWinner: PromiseVoid
}

export const useCommands = (): UseCommands => {
	const dispatch = useAppDispatch()
	const {
		commands,
		featureFlags: {
			data: { segmented_show_backup: segmentedShowBackupEnabled, segmented_show: segmentedShowEnabled },
		},
	} = useAppSelector(({ commands, featureFlags }: StateTree) => ({
		commands,
		featureFlags,
	}))
	const sendWarmUp = useCallback(() => warmUp()(dispatch), [dispatch])
	const [sendStartCountdownAndShow, toggleCountdownAndShow] = useCountdownShow()
	const [sendStartGame, toggleStartGame] = useStartGame()
	const [sendPrepareCelebration, togglePrepareCelebration] = usePrepareCelebration()
	const [sendDisplayBeatGuest, toggleDisplayBeatGuest] = useDisplayBeatGuest()
	const [sendPickWinners, togglePickWinners] = usePickWinners()
	const [sendDisplayWinners, toggleDisplayWinners] = useDisplayWinners()
	const [sendResetChallenges, toggleResetChallenges] = useResetChallenges()
	const [sendShowLuckyWinners, toggleShowLuckyWinners] = useShowLuckyWinners()
	const [sendStartPartBGame, toggleStartPartBGame] = useStartPartBGame()
	const [sendStopShow, toggleStopShow] = useStopShow()
	const sendSaveShow = useSaveShow()
	const sendPickSeasonWinner = usePickSeasonWinner()
	const [sendOverwriteDefaultValues] = useDefaultValues()
	const validateAllFields = useAllFieldsValidation(segmentedShowEnabled || segmentedShowBackupEnabled, segmentedShowEnabled)
	const getScheduledShow = useGetScheduledShow()

	const toggleCommands = (isAvailable: boolean): void => {
		toggleCountdownAndShow(isAvailable)
		toggleStartGame(isAvailable)
		togglePrepareCelebration(isAvailable)
		toggleDisplayBeatGuest(isAvailable)
		togglePickWinners(isAvailable)
		toggleDisplayWinners(isAvailable)
		toggleResetChallenges(isAvailable)
		toggleShowLuckyWinners(isAvailable)
		toggleStartPartBGame(isAvailable)
		toggleStopShow(isAvailable)

		if (isAvailable) {
			dispatch(onWSResetStateAction())
		}
	}

	const saveShowWithValidation = async (isNextShow: boolean): Promise<void> => {
		dispatch(saveScheduledShowInformationStart())
		if (isNextShow) dispatch(saveShowInformationStart())
		const isValid = await validateAllFields()

		if (isValid) {
			sendSaveShow(isNextShow)
		} else {
			console.error('invalid input values')
			dispatch(saveScheduledShowInformationFailure())
			if (isNextShow) dispatch(saveShowInformationFailure())
		}
	}

	const overwriteDefaultValuesWithValidation = async (): Promise<void> => {
		dispatch(overwriteDefaultValuesStart())

		const isValid = await validateAllFields()

		if (isValid) {
			sendOverwriteDefaultValues()
		} else {
			console.error('invalid input values')
			dispatch(overwriteDefaultValuesFailure())
		}
	}

	return {
		commands,
		sendWarmUp,
		sendStartCountdownAndShow,
		sendStartGame,
		sendPrepareCelebration,
		sendDisplayBeatGuest,
		sendPickWinners,
		sendDisplayWinners,
		sendResetChallenges,
		sendStopShow,
		sendShowLuckyWinners,
		sendStartPartBGame,
		toggleCommands,
		saveShowWithValidation,
		getScheduledShow,
		overwriteDefaultValuesWithValidation,
		sendPickSeasonWinner,
	}
}
