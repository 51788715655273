import { useCallback } from 'react'
import { timestampsAutomationSettingsStateValid } from '../../../redux/dashboardInputs/actions/timestampsAutomationSettingsState'
import type { TimestampsAutomationSettingsValidActionProps } from '../../../redux/dashboardInputs/interfaces/TimestampsAutomation'
import type { StateTree } from '../../../redux/interfaces'
import { validateShowTimestamps } from '../../validation/timestampsValid'
import { useAppDispatch, useAppSelector } from '../index'

export const useTimestampsValidation = (segmentedShowBackupEnabled = false): (() => boolean) => {
	const timestampsAutomationSettings = useAppSelector(
		({ dashboardInputs: { timestampsAutomationSettings } }: StateTree) => timestampsAutomationSettings,
	)
	const dispatch = useAppDispatch()
	const triggerDispatchValidation = useCallback(
		(props: TimestampsAutomationSettingsValidActionProps) => {
			dispatch(timestampsAutomationSettingsStateValid(props))
		},
		[dispatch],
	)

	return (): boolean => {
		const isValid = validateShowTimestamps(timestampsAutomationSettings.value, segmentedShowBackupEnabled)
		if (isValid !== timestampsAutomationSettings.isValid) {
			triggerDispatchValidation({ isValid })
		}

		return isValid
	}
}
