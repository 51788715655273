import { useCallback } from 'react'
import posterInfoState from '../../../redux/dashboardInputs/actions/posterInfoState'
import type { PosterInfoState } from '../../../redux/dashboardInputs/interfaces/PosterInfoState'
import type { UpdatePosterInfoStateProps } from '../../../redux/dashboardInputs/interfaces/UpdatePosterInfoStateProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

export type UsePosterInfoUpdate = [
	Omit<PosterInfoState, 'guestImageWithName'>,
	(props: Omit<UpdatePosterInfoStateProps, 'guestImageWithName'>) => void,
]

export const usePosterInfoUpdate = (): UsePosterInfoUpdate => {
	const { posterInfo } = useAppSelector(({ dashboardInputs: { posterInfo } }: StateTree) => ({
		posterInfo,
	}))
	const dispatch = useAppDispatch()
	const sendUpdatePosterInfoState = useCallback(
		(props: Omit<UpdatePosterInfoStateProps, 'guestImageWithName'>) => posterInfoState(props)(dispatch),
		[dispatch],
	)

	return [posterInfo, sendUpdatePosterInfoState]
}
