import type { ChangeEvent, FunctionComponent, MouseEvent, ReactElement } from 'react'
import { Fragment } from 'react'
import { tooltipId } from '../../config/const'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardInput, CardTextArea, CardWrapper } from '../../containers/Card'
import type { UseGuestInfoUpdateCommand } from '../../utils/hooks/commands/useGuestInfoUpdateCommand'
import type { UseGuestInfoUpdate } from '../../utils/hooks/dashboardInputs/useGuestInfoUpdate'

type Props = {
	title: string
	useGuestInfoUpdate: () => UseGuestInfoUpdate
	useGuestInfoUpdateCommand: () => UseGuestInfoUpdateCommand
}

function GuestAnnouncementInfo({
	title,
	useGuestInfoUpdate,
	useGuestInfoUpdateCommand,
}: Props): ReactElement<typeof Fragment, FunctionComponent<typeof Fragment>> {
	const [guestInfoState, updateGuestInfoState] = useGuestInfoUpdate()

	const [{ isUpdating }, updateGuestInfoCommand] = useGuestInfoUpdateCommand()

	const getGuestInfoValues = () => {
		const {
			guestDetailsPictureURL: { value: guestDetailsPictureURL },
			guestDetails: { value: guestDetails },
			guestExternalURL: { value: guestExternalURL },
			guestRoomPosterPictureURL: { value: guestRoomPosterPictureURL },
			guestTitle: { value: guestTitle },
		} = guestInfoState

		return {
			guestDetailsPictureURL,
			guestDetails,
			guestExternalURL,
			guestRoomPosterPictureURL,
			guestTitle,
		}
	}

	const onChangeRoomPicture = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value },
		} = event

		updateGuestInfoState({
			...getGuestInfoValues(),
			guestRoomPosterPictureURL: value,
		})
	}

	const onChangeWidgetPicture = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value },
		} = event

		updateGuestInfoState({
			...getGuestInfoValues(),
			guestDetailsPictureURL: value,
		})
	}

	const onChangeGuestName = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value },
		} = event

		updateGuestInfoState({ ...getGuestInfoValues(), guestTitle: value })
	}

	const onChangeExternalLink = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value },
		} = event

		updateGuestInfoState({ ...getGuestInfoValues(), guestExternalURL: value })
	}

	const onChangeGuestBio = (event: ChangeEvent<HTMLTextAreaElement>): void => {
		const {
			currentTarget: { value },
		} = event

		updateGuestInfoState({ ...getGuestInfoValues(), guestDetails: value })
	}

	const onUpdateClick = (event: MouseEvent<HTMLButtonElement>): void => {
		event.stopPropagation()

		updateGuestInfoCommand()
	}

	return (
		<Fragment>
			<CardWrapper>
				<CardHeader>
					<CardHeading>{title}</CardHeading>
				</CardHeader>
				<CardBody>
					<CardFieldSet>
						<label>Room Picture URL</label>
						<CardInput
							value={guestInfoState.guestRoomPosterPictureURL.value}
							type="text"
							onChange={onChangeRoomPicture}
							isValid={guestInfoState.guestRoomPosterPictureURL.isValid}
							placeholder="Room Picture URL"
							data-tooltip-content={guestInfoState.guestRoomPosterPictureURL.value}
							data-tooltip-id={tooltipId}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>Widget Picture URL</label>
						<CardInput
							value={guestInfoState.guestDetailsPictureURL.value}
							type="text"
							onChange={onChangeWidgetPicture}
							isValid={guestInfoState.guestDetailsPictureURL.isValid}
							placeholder="Widget Picture URL"
							data-tooltip-content={guestInfoState.guestDetailsPictureURL.value}
							data-tooltip-id={tooltipId}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>Guest Name</label>
						<CardInput
							value={guestInfoState.guestTitle.value}
							type="text"
							onChange={onChangeGuestName}
							isValid={guestInfoState.guestTitle.isValid}
							placeholder="Guest Name"
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>Guest Bio</label>
						<CardTextArea value={guestInfoState.guestDetails.value} placeholder="Guest Bio" onChange={onChangeGuestBio} />
					</CardFieldSet>
					<CardFieldSet>
						<label>External Link</label>
						<CardInput
							value={guestInfoState.guestExternalURL.value}
							type="text"
							onChange={onChangeExternalLink}
							isValid={guestInfoState.guestExternalURL.isValid}
							placeholder="External Link"
							data-tooltip-content={guestInfoState.guestExternalURL.value}
							data-tooltip-id={tooltipId}
						/>
					</CardFieldSet>

					<CardFieldSet>
						<CardButton type="button" disabled={isUpdating} onClick={onUpdateClick}>
							Overwrite
						</CardButton>
					</CardFieldSet>
				</CardBody>
			</CardWrapper>
		</Fragment>
	)
}

export default GuestAnnouncementInfo
