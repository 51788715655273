import type { Dispatch } from 'redux'
import { Game } from '../../../config/enums'
import { sendCommandToStudio } from '../../../services/api/serverless'
import { getDateTimeTimestamp } from '../../../utils/getDateTimeTimestamp'
import { parseBaseGameSettingsValues, parseBlockbusterSettingsValues } from '../../../utils/getGameSettingsValues'
import { getNextGoals } from '../../../utils/getNextGoals'
import type { StateTree } from '../../interfaces'
import type {
	ResetChallengesFailureAction,
	ResetChallengesStartAction,
	ResetChallengesSuccessAction,
} from '../interfaces/actions/ResetChallengesActions'
import type { ResetChallenges } from '../interfaces/ResetChallenges'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const resetChallengesStart = (): ResetChallengesStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.RESET_CHALLENGES_START }
}

const resetChallengesFailure = (): ResetChallengesFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.RESET_CHALLENGES_FAILURE }
}

const resetChallengesSuccess = (): ResetChallengesSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.RESET_CHALLENGES_SUCCESS }
}

export default function resetChallenges() {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(resetChallengesStart())

		try {
			const {
				dashboardInputs: {
					featuredGame,
					nextShowDateTime,
					blockbusterSettings,
					centipedeSettings,
					beatSettings,
					rallySettings,
					breakoutSettings,
					nextDailyGoals,
					nextWeeklyGoals,
					nextMonthlyGoals,
					guestInfo,
					posterInfo,
					nextPosterInfo,
				},
				commands: {
					seasonTransition: { seasonTransitionStatus: seasonTransition },
				},
			} = getState()

			const gameSettings = {
				[Game.Blockbuster]: parseBlockbusterSettingsValues(blockbusterSettings),
				[Game.Centipede]: parseBaseGameSettingsValues(centipedeSettings, Game.Centipede),
				[Game.Beat]: parseBaseGameSettingsValues(beatSettings, Game.Beat),
				[Game.Rally]: parseBaseGameSettingsValues(rallySettings, Game.Rally),
				[Game.Breakout]: parseBaseGameSettingsValues(breakoutSettings, Game.Breakout),
			}

			const nextGuestPosterInfo = {
				guestRoomPosterPictureURL: guestInfo.guestRoomPosterPictureURL.value,
				guestDetailsPictureURL: guestInfo.guestDetailsPictureURL.value,
				guestTitle: guestInfo.guestTitle.value,
				guestDetails: guestInfo.guestDetails.value,
				guestExternalURL: guestInfo.guestExternalURL.value,
				Guest: {
					guest: {
						name: posterInfo.guestName.value,
						bio: posterInfo.guestBio.value,
						cta: posterInfo.guestCta.value,
						socialHandle: posterInfo.guestSocialHandle.value,
						socialHyperlink: posterInfo.guestSocialHyperlink.value,
						imageWithoutName: posterInfo.guestImageWithoutName.value,
					},
					host: {
						name: posterInfo.hostName.value,
						socialHandle: posterInfo.hostSocialHandle.value,
						socialHyperlink: posterInfo.hostSocialHyperlink.value,
						imageWithoutName: posterInfo.hostImageWithoutName.value,
					},
					gameName: posterInfo.game.value,
				},
				NextGuest: {
					guest: {
						name: nextPosterInfo.guestName.value,
						bio: nextPosterInfo.guestBio.value,
						socialHandle: nextPosterInfo.guestSocialHandle.value,
						socialHyperlink: nextPosterInfo.guestSocialHyperlink.value,
						imageWithName: nextPosterInfo.guestImageWithName.value,
						imageWithoutName: nextPosterInfo.guestImageWithoutName.value,
					},
					host: {
						name: nextPosterInfo.hostName.value,
						socialHandle: nextPosterInfo.hostSocialHandle.value,
						socialHyperlink: nextPosterInfo.hostSocialHyperlink.value,
						imageWithoutName: nextPosterInfo.hostImageWithoutName.value,
					},
					gameName: nextPosterInfo.game.value,
				},
			}

			const resetChallengesCommand: ResetChallenges = {
				command: 'resetChallenges',
				gameSettings,
				featuredGame,
				nextShowTimeStamp: getDateTimeTimestamp(nextShowDateTime),
				nextGoals: getNextGoals({ nextDailyGoals, nextWeeklyGoals, nextMonthlyGoals }),
				seasonTransition,
				nextGuestPosterInfo,
			}

			await sendCommandToStudio(resetChallengesCommand)

			dispatch(resetChallengesSuccess())
		} catch {
			dispatch(resetChallengesFailure())
		}
	}
}
