import type { ChangeEvent, FunctionComponent, MouseEvent, ReactElement } from 'react'
import { Fragment } from 'react'
import { tooltipId } from '../../config/const'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardInput, CardTextArea, CardWrapper } from '../../containers/Card'
import type { UsePosterInfoUpdateCommand } from '../../utils/hooks/commands/usePosterInfoUpdateCommand'
import type { UsePosterInfoUpdate } from '../../utils/hooks/dashboardInputs/usePosterInfoUpdate'
import type { UseNextPosterInfoUpdate } from '../../utils/hooks/dashboardInputs/useNextPosterInfoUpdate'
import { gameNames } from '../../config/app'
import { PosterInfoState } from '../../redux/dashboardInputs/interfaces/PosterInfoState'
import { UpdatePosterInfoStateProps } from '../../redux/dashboardInputs/interfaces/UpdatePosterInfoStateProps'

type Props = {
	title: string
	usePosterInfoUpdate: () => UsePosterInfoUpdate | UseNextPosterInfoUpdate
	usePosterInfoUpdateCommand: () => UsePosterInfoUpdateCommand
}

function PosterInfo({
	title,
	usePosterInfoUpdate,
	usePosterInfoUpdateCommand,
}: Props): ReactElement<typeof Fragment, FunctionComponent<typeof Fragment>> {
	const [posterInfoState, updatePosterInfoState] = usePosterInfoUpdate()

	const [{ isUpdating }, updatePosterInfoCommand] = usePosterInfoUpdateCommand()

	const getPosterInfoValues = () => {
		const {
			guestName: { value: guestName },
			guestBio: { value: guestBio },
			guestCta: { value: guestCta } = {},
			guestImageWithName: { value: guestImageWithName } = {},
			guestImageWithoutName: { value: guestImageWithoutName },
			guestSocialHandle: { value: guestSocialHandle },
			guestSocialHyperlink: { value: guestSocialHyperlink },
			hostName: { value: hostName },
			hostImageWithoutName: { value: hostImageWithoutName },
			hostSocialHandle: { value: hostSocialHandle },
			hostSocialHyperlink: { value: hostSocialHyperlink },
			game: { value: game },
		} = posterInfoState as PosterInfoState

		return {
			guestName,
			guestBio,
			guestCta,
			guestImageWithName,
			guestImageWithoutName,
			guestSocialHandle,
			guestSocialHyperlink,
			hostName,
			hostImageWithoutName,
			hostSocialHandle,
			hostSocialHyperlink,
			game,
		} as UpdatePosterInfoStateProps
	}

	const onChange =
		(inputName: string) =>
		(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
			const {
				currentTarget: { value },
			} = event

			updatePosterInfoState({ ...getPosterInfoValues(), [inputName]: value })
		}

	const onUpdateClick = (event: MouseEvent<HTMLButtonElement>): void => {
		event.stopPropagation()

		updatePosterInfoCommand()
	}

	return (
		<Fragment>
			<CardWrapper>
				<CardHeader>
					<CardHeading>{title}</CardHeading>
				</CardHeader>
				<CardBody>
					<CardFieldSet>
						<label>Guest Name</label>
						<CardInput
							value={posterInfoState.guestName.value}
							type="text"
							onChange={onChange('guestName')}
							isValid={posterInfoState.guestName.isValid}
							placeholder="Guest Name"
							data-tooltip-content={posterInfoState.guestName.value}
							data-tooltip-id={tooltipId}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>Guest Bio</label>
						<CardInput
							value={posterInfoState.guestBio.value}
							type="text"
							onChange={onChange('guestBio')}
							isValid={posterInfoState.guestBio.isValid}
							placeholder="Guest Bio"
							data-tooltip-content={posterInfoState.guestBio.value}
							data-tooltip-id={tooltipId}
						/>
					</CardFieldSet>
					{(posterInfoState as PosterInfoState).guestCta && (
						<CardFieldSet>
							<label>Guest CTA</label>
							<CardInput
								value={(posterInfoState as PosterInfoState).guestCta.value}
								type="text"
								onChange={onChange('guestCta')}
								isValid={(posterInfoState as PosterInfoState).guestCta.isValid}
								placeholder="Guest CTA"
								data-tooltip-content={(posterInfoState as PosterInfoState).guestCta.value}
								data-tooltip-id={tooltipId}
							/>
						</CardFieldSet>
					)}
					<CardFieldSet>
						<label>Guest Social Handle</label>
						<CardInput
							value={posterInfoState.guestSocialHandle.value}
							type="text"
							onChange={onChange('guestSocialHandle')}
							isValid={posterInfoState.guestSocialHandle.isValid}
							placeholder="Guest Social Handle"
							data-tooltip-content={posterInfoState.guestSocialHandle.value}
							data-tooltip-id={tooltipId}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>Guest Social URL</label>
						<CardInput
							value={posterInfoState.guestSocialHyperlink.value}
							type="text"
							onChange={onChange('guestSocialHyperlink')}
							isValid={posterInfoState.guestSocialHyperlink.isValid}
							placeholder="Guest Social URL"
							data-tooltip-content={posterInfoState.guestSocialHyperlink.value}
							data-tooltip-id={tooltipId}
						/>
					</CardFieldSet>
					{(posterInfoState as PosterInfoState).guestImageWithName && (
						<CardFieldSet>
							<label>Guest Image With Name URL</label>
							<CardInput
								value={(posterInfoState as PosterInfoState).guestImageWithName.value}
								type="text"
								onChange={onChange('guestImageWithName')}
								isValid={(posterInfoState as PosterInfoState).guestImageWithName.isValid}
								placeholder="Guest Image With Name URL"
								data-tooltip-content={(posterInfoState as PosterInfoState).guestImageWithName.value}
								data-tooltip-id={tooltipId}
							/>
						</CardFieldSet>
					)}
					<CardFieldSet>
						<label>Guest Image Without Name URL</label>
						<CardInput
							value={posterInfoState.guestImageWithoutName.value}
							type="text"
							onChange={onChange('guestImageWithoutName')}
							isValid={posterInfoState.guestImageWithoutName.isValid}
							placeholder="Guest Image Without Name URL"
							data-tooltip-content={posterInfoState.guestImageWithoutName.value}
							data-tooltip-id={tooltipId}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>Host Name</label>
						<CardInput
							value={posterInfoState.hostName.value}
							type="text"
							onChange={onChange('hostName')}
							isValid={posterInfoState.hostName.isValid}
							placeholder="Host Name"
							data-tooltip-content={posterInfoState.hostName.value}
							data-tooltip-id={tooltipId}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>Host Social Handle</label>
						<CardInput
							value={posterInfoState.hostSocialHandle.value}
							type="text"
							onChange={onChange('hostSocialHandle')}
							isValid={posterInfoState.hostSocialHandle.isValid}
							placeholder="Host Social Handle"
							data-tooltip-content={posterInfoState.hostSocialHandle.value}
							data-tooltip-id={tooltipId}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>Host Social URL</label>
						<CardInput
							value={posterInfoState.hostSocialHyperlink.value}
							type="text"
							onChange={onChange('hostSocialHyperlink')}
							isValid={posterInfoState.hostSocialHyperlink.isValid}
							placeholder="Host Social URL"
							data-tooltip-content={posterInfoState.hostSocialHyperlink.value}
							data-tooltip-id={tooltipId}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>Host Image Without Name URL</label>
						<CardInput
							value={posterInfoState.hostImageWithoutName.value}
							type="text"
							onChange={onChange('hostImageWithoutName')}
							isValid={posterInfoState.hostImageWithoutName.isValid}
							placeholder="Host Image Without Name URL"
							data-tooltip-content={posterInfoState.hostImageWithoutName.value}
							data-tooltip-id={tooltipId}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>Game: </label>
						<select value={posterInfoState.game.value} onChange={onChange('game')}>
							{gameNames.map((item) => (
								<option key={item.key} value={item.name}>
									{item.key}
								</option>
							))}
						</select>
					</CardFieldSet>

					<CardFieldSet>
						<CardButton type="button" disabled={isUpdating} onClick={onUpdateClick}>
							Overwrite
						</CardButton>
					</CardFieldSet>
				</CardBody>
			</CardWrapper>
		</Fragment>
	)
}

export default PosterInfo
