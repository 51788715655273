import { useCallback } from 'react'
import nextPosterInfoState from '../../../redux/dashboardInputs/actions/nextPosterInfoState'
import type { UpdatePosterInfoStateProps } from '../../../redux/dashboardInputs/interfaces/UpdatePosterInfoStateProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'
import type { PosterInfoState } from '../../../redux/dashboardInputs/interfaces/PosterInfoState'

export type UseNextPosterInfoUpdate = [Omit<PosterInfoState, 'guestCta'>, (props: Omit<UpdatePosterInfoStateProps, 'guestCta'>) => void]

export const useNextPosterInfoUpdate = (): UseNextPosterInfoUpdate => {
	const { nextPosterInfo } = useAppSelector(({ dashboardInputs: { nextPosterInfo } }: StateTree) => ({
		nextPosterInfo,
	}))
	const dispatch = useAppDispatch()
	const sendUpdateNextPosterInfoState = useCallback(
		(props: Omit<UpdatePosterInfoStateProps, 'guestCta'>) => nextPosterInfoState(props)(dispatch),
		[dispatch],
	)

	return [nextPosterInfo, sendUpdateNextPosterInfoState]
}
