import type { FunctionComponent, ReactElement } from 'react'
import { useEffect } from 'react'
import { Game } from '../../config/enums'
import { CardGroup, CardGroupDelimiter } from '../../containers/Card'
import { useDefaultValues } from '../../utils/hooks/commands/useDefaultValues'
import { useSeasonTransitionToggle } from '../../utils/hooks/commands/useSeasonTransitionToggle'
import AnalyticsCuePoints from '../AnalyticsCuePoints'
import BaseGameSettings from '../BaseGameSettings'
import BeatGuestMessages from '../BeatGuestMessages'
import BlockbusterSettings from '../BlockbusterSettings'
import ChatBans from '../ChatBans'
import DashboardHeader from '../DashboardHeader'
import EmployeeMarker from '../EmployeeMarker'
import FeaturedGame from '../FeaturedGame'
import ForceUpdateVersion from '../ForceUpdateVersion'
import GuestInfo from '../GuestInfo'
import MaintenanceMode from '../MaintenanceMode'
import MaintenanceWhitelist from '../MaintenanceWhitelist'
import News from '../News'
import NextDailyGoals from '../NextDailyGoals'
import NextMonthlyGoals from '../NextMonthlyGoals'
import NextShowTime from '../NextShowTime'
import NextWeeklyGoals from '../NextWeeklyGoals'
import Prizes from '../Prizes'
import ReferralCode from '../ReferralCode'
import SeasonWinner from '../SeasonWinner'
import TimestampsAutomation from '../TimestampsAutomation'
import Video from '../Video'
import { DashboardContainer } from './DashboardContainer'
import { useVideoState } from '../../utils/hooks/dashboardInputs/useVideoState'
import { useOnDemandVideoState } from '../../utils/hooks/dashboardInputs/useOnDemandVideoState'
import { useFeatureFlagsState } from '../../utils/hooks/useFeatureFlagsState'
import { useGuestInfoUpdate } from '../../utils/hooks/dashboardInputs/useGuestInfoUpdate'
import { usePosterInfoUpdate } from '../../utils/hooks/dashboardInputs/usePosterInfoUpdate'
import { useGuestInfoUpdateCommand } from '../../utils/hooks/commands/useGuestInfoUpdateCommand'
import { usePosterInfoUpdateCommand } from '../../utils/hooks/commands/usePosterInfoUpdateCommand'
import { useNextPosterInfoUpdate } from '../../utils/hooks/dashboardInputs/useNextPosterInfoUpdate'
import { useNextPosterInfoUpdateCommand } from '../../utils/hooks/commands/useNextPosterInfoUpdateCommand'
import { useTimestampAutomationSettings } from '../../utils/hooks/dashboardInputs/useTimestampAutomationSettings'
import { useOnDemandTimestampSettings } from '../../utils/hooks/dashboardInputs/useOnDemandTimestampsSettings'
import type { TimestampAutomationCommand, OnDemandTimestampCommand } from '../../config/enums'
import type { TimestampProps } from '../../redux/dashboardInputs/interfaces/TimestampProps'
import { timestampAutomationCommandTitles, onDemandTimestampCommandTitles } from '../../config/app'
import PosterInfo from '../PosterInfo'

function Dashboard(): ReactElement<typeof DashboardContainer, FunctionComponent<typeof DashboardContainer>> {
	const [, replaceValuesWithDefaults] = useDefaultValues()
	const [seasonTransition] = useSeasonTransitionToggle()
	const [
		{
			data: { segmented_show_backup: segmentedShowBackupEnabled, segmented_show: segmentedShowEnabled },
		},
	] = useFeatureFlagsState()

	const [timestampsSettings, updateTimestampSettingsState] = useTimestampAutomationSettings()
	const { ShowLuckyWinners, StartPartBGame, StopShow, ...rest } = timestampsSettings.value
	const timestampSettingsValue = {
		...rest,
		...((segmentedShowEnabled || segmentedShowBackupEnabled) && { ShowLuckyWinners }),
		...(segmentedShowEnabled && { StartPartBGame }),
		StopShow,
	} as Record<TimestampAutomationCommand, TimestampProps>

	const [onDemandTimestampsSettings, updateOnDemandTimestampSettingsState] = useOnDemandTimestampSettings()

	useEffect(() => {
		replaceValuesWithDefaults()
	}, [replaceValuesWithDefaults])

	return (
		<DashboardContainer>
			<DashboardHeader />
			<CardGroupDelimiter />
			<CardGroup>
				<CardGroup flexDirection={'column'}>
					<Video title="Video & Guest Score" useVideoState={useVideoState} />
					{segmentedShowEnabled && <Video title="On Demand Video & Guest Score" useVideoState={useOnDemandVideoState} />}
				</CardGroup>
				<CardGroup flexDirection={'column'}>
					<TimestampsAutomation<TimestampAutomationCommand>
						title="Timestamps"
						timestampsSettings={timestampSettingsValue}
						updateTimestampsSettings={updateTimestampSettingsState}
						isValid={timestampsSettings.isValid}
						commandTitles={timestampAutomationCommandTitles}
					/>
					{segmentedShowEnabled && (
						<TimestampsAutomation<OnDemandTimestampCommand>
							title="On Demand Timestamps"
							timestampsSettings={onDemandTimestampsSettings.value}
							updateTimestampsSettings={updateOnDemandTimestampSettingsState}
							isValid={onDemandTimestampsSettings.isValid}
							commandTitles={onDemandTimestampCommandTitles}
						/>
					)}
					<AnalyticsCuePoints />
				</CardGroup>
				<BeatGuestMessages />
				<CardGroup>
					{segmentedShowEnabled ? (
						<>
							<PosterInfo
								title="Guest Poster Info"
								usePosterInfoUpdate={usePosterInfoUpdate}
								usePosterInfoUpdateCommand={usePosterInfoUpdateCommand}
							/>
							<PosterInfo
								title="Next Guest Poster Info"
								usePosterInfoUpdate={useNextPosterInfoUpdate}
								usePosterInfoUpdateCommand={useNextPosterInfoUpdateCommand}
							/>
						</>
					) : (
						<GuestInfo
							title="Guest Poster Info"
							useGuestInfoUpdate={useGuestInfoUpdate}
							useGuestInfoUpdateCommand={useGuestInfoUpdateCommand}
						/>
					)}
				</CardGroup>
			</CardGroup>
			<CardGroupDelimiter />
			<CardGroup>
				<BlockbusterSettings />
				<BaseGameSettings gameName={Game.Centipede} />
				<BaseGameSettings gameName={Game.Beat} />
				<BaseGameSettings gameName={Game.Rally} />
				<BaseGameSettings gameName={Game.Breakout} />
				<FeaturedGame />
			</CardGroup>
			<CardGroupDelimiter />
			<CardGroup>
				<NextDailyGoals />
				<NextWeeklyGoals />
				<NextMonthlyGoals />
			</CardGroup>
			<CardGroupDelimiter />
			<CardGroup>
				<NextShowTime />
				<Prizes />
				{seasonTransition.seasonTransitionStatus && <SeasonWinner />}
			</CardGroup>
			<CardGroupDelimiter />
			<ChatBans />
			<CardGroupDelimiter />
			<News />
			<CardGroupDelimiter />
			<CardGroup>
				<ForceUpdateVersion />
				<MaintenanceMode />
				<MaintenanceWhitelist />
				<EmployeeMarker />
				<ReferralCode />
			</CardGroup>
		</DashboardContainer>
	)
}

export default Dashboard
