import type { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { Fragment } from 'react'
import { tooltipId } from '../../config/const'
import { CardBody, CardFieldSet, CardHeader, CardHeading, CardInput, CardTextArea, CardWrapper } from '../../containers/Card'
import type { UseVideoState } from '../../utils/hooks/dashboardInputs/useVideoState'

interface Props {
	title: string
	useVideoState: () => UseVideoState
}

function Video({ title, useVideoState }: Props): ReactElement<typeof Fragment, FunctionComponent<typeof Fragment>> {
	const [highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore, updateState] = useVideoState()

	const onChangeHighQualityVideoUrl = (event: ChangeEvent<HTMLTextAreaElement>) => {
		const {
			currentTarget: { value },
		} = event
		updateState({
			highQualityVideoUrl: value,
			videoUrl: videoUrl.value,
			lowQualityVideoUrl: lowQualityVideoUrl.value,
			guestScore: guestScore.value,
		})
	}

	const onChangeVideoUrl = (event: ChangeEvent<HTMLTextAreaElement>) => {
		const {
			currentTarget: { value },
		} = event
		updateState({
			highQualityVideoUrl: highQualityVideoUrl.value,
			videoUrl: value,
			lowQualityVideoUrl: lowQualityVideoUrl.value,
			guestScore: guestScore.value,
		})
	}

	const onChangeLowQualityVideoUrl = (event: ChangeEvent<HTMLTextAreaElement>) => {
		const {
			currentTarget: { value },
		} = event
		updateState({
			highQualityVideoUrl: highQualityVideoUrl.value,
			videoUrl: videoUrl.value,
			lowQualityVideoUrl: value,
			guestScore: guestScore.value,
		})
	}

	const onChangeGuestScore = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
		} = event
		updateState({
			highQualityVideoUrl: highQualityVideoUrl.value,
			videoUrl: videoUrl.value,
			lowQualityVideoUrl: lowQualityVideoUrl.value,
			guestScore: Number(value),
		})
	}

	return (
		<Fragment>
			<CardWrapper>
				<CardHeader>
					<CardHeading>{title}</CardHeading>
				</CardHeader>
				<CardBody>
					<CardFieldSet>
						<label>High Quality Video URL</label>
						<CardTextArea
							value={highQualityVideoUrl.value}
							placeholder="Video link"
							onChange={onChangeHighQualityVideoUrl}
							data-tooltip-content={highQualityVideoUrl.value}
							data-tooltip-id={tooltipId}
							// isValid={highQualityVideoUrl.isValid}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>Standard Quality Video URL</label>
						<CardTextArea
							value={videoUrl.value}
							placeholder="Video link"
							onChange={onChangeVideoUrl}
							data-tooltip-content={videoUrl.value}
							data-tooltip-id={tooltipId}
							// // isValid={videoUrl.isValid}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>Low Quality Video URL</label>
						<CardTextArea
							value={lowQualityVideoUrl.value}
							placeholder="Video link"
							onChange={onChangeLowQualityVideoUrl}
							data-tooltip-content={lowQualityVideoUrl.value}
							data-tooltip-id={tooltipId}
							// // isValid={lowQualityVideoUrl.isValid}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>Score</label>
						<CardInput
							type="number"
							value={guestScore.value}
							placeholder="Guest Score"
							onChange={onChangeGuestScore}
							isValid={guestScore.isValid}
							min={0}
						/>
					</CardFieldSet>
				</CardBody>
			</CardWrapper>
		</Fragment>
	)
}

export default Video
