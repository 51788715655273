import type { Dispatch } from 'redux'
import type { GuestInfoStateValidAction } from '../interfaces/GuestInfoStateValidAction'
import type { GuestInfoStateValidProps } from '../interfaces/GuestInfoStateValidProps'
import type { UpdateGuestInfoStateAction } from '../interfaces/UpdateGuestInfoStateAction'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'
import { DEPRECATED_MainMenuContent } from '../../studioCommand/interfaces/UpdateMainMenuContent'

export const updateGuestInfoState = (props: DEPRECATED_MainMenuContent): UpdateGuestInfoStateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.GUEST_INFO_STATE_UPDATE,
		props,
	}
}

export const isValidGuestInfoState = (props: GuestInfoStateValidProps): GuestInfoStateValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.GUEST_INFO_STATE_VALID,
		props,
	}
}

export default function guestInfoState(props: DEPRECATED_MainMenuContent) {
	return (dispatch: Dispatch): void => {
		dispatch(updateGuestInfoState(props))
	}
}
