import { combineReducers } from 'redux'
import country from './auth/reducers/selectCountry'
import auth from './auth/reducers/verifyJWT'
import chatBans from './chatBans/reducers/chatBans'
import analyticsCuePointsState from './dashboardInputs/reducers/analyticsCuePointsState'
import beatGuestMessages from './dashboardInputs/reducers/beatGuestMessages'
import beatSettings from './dashboardInputs/reducers/beatSettingsState'
import blockbusterSettings from './dashboardInputs/reducers/blockbusterSettingsState'
import breakoutSettings from './dashboardInputs/reducers/breakoutSettingsState'
import centipedeSettings from './dashboardInputs/reducers/centipedeSettingsState'
import featuredGame from './dashboardInputs/reducers/featuredGameState'
import forceUpdateVersionState from './dashboardInputs/reducers/forceUpdateVersion'
import guestInfo from './dashboardInputs/reducers/guestInfo'
import posterInfo from './dashboardInputs/reducers/posterInfo'
import nextPosterInfo from './dashboardInputs/reducers/nextPosterInfo'
import nextDailyGoals from './dashboardInputs/reducers/nextDailyGoalsState'
import nextMonthlyGoals from './dashboardInputs/reducers/nextMonthlyGoalsState'
import nextShowDateTime from './dashboardInputs/reducers/nextShowTimeState'
import nextWeeklyGoals from './dashboardInputs/reducers/nextWeeklyGoalsState'
import prizes from './dashboardInputs/reducers/prizes'
import rallySettings from './dashboardInputs/reducers/rallySettingsState'
import seasonWinnerState from './dashboardInputs/reducers/seasonWinnerState'
import timestampsAutomationSettings from './dashboardInputs/reducers/timestampsAutomationSettingsState'
import onDemandTimestampsSettings from './dashboardInputs/reducers/onDemandTimestampsSettingsState'
import video from './dashboardInputs/reducers/videoState'
import onDemandVideo from './dashboardInputs/reducers/onDemandVideoState'
import featureFlags from './featureFlags/reducers/featureFlagsState'
import type { RootReducer } from './interfaces'
import news from './news/reducers/newsState'
import executionShowInfo from './showAutomation/reducers/getStepFunctionExecutionId'
import showCalendar from './showCalendar/reducers/showCalendar'
import displayBeatGuest from './studioCommand/reducers/displayBeatGuest'
import displayWinners from './studioCommand/reducers/displayWinners'
import executeShow from './studioCommand/reducers/executeShow'
import forceUpdateVersion from './studioCommand/reducers/forceUpdateVersion'
import leaguesReset from './studioCommand/reducers/leaguesResetToggle'
import overwriteDefaultValues from './studioCommand/reducers/overwriteDefaultValues'
import overwriteNextGoals from './studioCommand/reducers/overwriteNextGoals'
import pickSeasonWinner from './studioCommand/reducers/pickSeasonWinner'
import pickWinners from './studioCommand/reducers/pickWinners'
import prepareCelebration from './studioCommand/reducers/prepareCelebration'
import resetChallenges from './studioCommand/reducers/resetChallenges'
import saveScheduledShowInfo from './studioCommand/reducers/saveScheduledShowInformation'
import saveShowInfo from './studioCommand/reducers/saveShowInformation'
import seasonTransition from './studioCommand/reducers/seasonTransitionToggle'
import showType from './studioCommand/reducers/showTypeSelect'
import startCountdownAndShow from './studioCommand/reducers/startCountdownAndShow'
import startGame from './studioCommand/reducers/startGame'
import showLuckyWinners from './studioCommand/reducers/showLuckyWinners'
import startPartBGame from './studioCommand/reducers/startPartBGame'
import stopShow from './studioCommand/reducers/stopShow'
import updateGuestInfo from './studioCommand/reducers/updateGuestInfo'
import updatePosterInfo from './studioCommand/reducers/updatePosterInfo'
import updateNextPosterInfo from './studioCommand/reducers/updateNextPosterInfo'
import updateNews from './studioCommand/reducers/updateNews'
import updateNextShowTime from './studioCommand/reducers/updateNextShowTime'
import warmUp from './studioCommand/reducers/warmUp'
import wsMessage from './webSocket/reducers/onWSAction'

const dashboardInputs = combineReducers({
	video,
	onDemandVideo,
	beatGuestMessages,
	prizes,
	nextShowDateTime,
	blockbusterSettings,
	centipedeSettings,
	beatSettings,
	rallySettings,
	breakoutSettings,
	featuredGame,
	nextDailyGoals,
	nextWeeklyGoals,
	nextMonthlyGoals,
	guestInfo,
	posterInfo,
	nextPosterInfo,
	forceUpdateVersion: forceUpdateVersionState,
	timestampsAutomationSettings,
	onDemandTimestampsSettings,
	analyticsCuePointsState,
	seasonWinnerState,
})

const commands = combineReducers({
	warmUp,
	startCountdownAndShow,
	startGame,
	prepareCelebration,
	displayBeatGuest,
	pickWinners,
	displayWinners,
	resetChallenges,
	showLuckyWinners,
	startPartBGame,
	stopShow,
	pickSeasonWinner,
	leaguesReset,
	seasonTransition,
	saveShowInfo,
	saveScheduledShowInfo,
	updateGuestInfo,
	updatePosterInfo,
	updateNextPosterInfo,
	overwriteNextGoals,
	updateNextShowTime,
	forceUpdateVersion,
	overwriteDefaultValues,
	updateNews,
	executeShow,
	showType,
})

export const rootReducer: RootReducer = combineReducers({
	auth,
	country,
	executionShowInfo,
	wsMessage,
	commands,
	dashboardInputs,
	news,
	chatBans,
	showCalendar,
	featureFlags,
})
